import { Ref } from "vue";
import { useCookies } from "vue3-cookies";
import { usePostHydrationState } from "./postHydrationState";

export type ReactiveCookieConsent = {
  strictlyNecessary: Ref<boolean>,
  functionality: Ref<boolean>,
  targeting: Ref<boolean>,
  tracking: Ref<boolean>,
  accepted: Ref<boolean>
};


const expireTimes = (60 * 60 * 24 * 180);

const { accepted, strictlyNecessary, functionality, targeting, tracking } = usePostHydrationState();

export function useCookieConsent(): ReactiveCookieConsent {

  return { functionality, strictlyNecessary, targeting, tracking, accepted };

}

export function acceptCookieConsent() {
  const { cookies } = useCookies();
  cookies.set('cookie_consent_user_accepted', "true", expireTimes);
  accepted.value = true;
}

export function updateCookieConsent() {
  const { cookies } = useCookies();

  cookies.set('cookie_consent_level', {
    functionality: functionality.value,
    "strictly-necessary": strictlyNecessary.value,
    targeting: targeting.value,
    tracking: tracking.value
  } as unknown as string, expireTimes);

}

